import { Tag } from "../components/Data";
import {
  errorParser,
  fetchJSON,
  LOAD_TOPENTRIES_KEY,
  loadTopEntries,
} from "../components/Loaders";
import { IUIStore, useUIStore } from "../components/Store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const LOAD_TAGS_KEY = ["tags"];
const loadTags = (sessionId: string) => async (): Promise<Tag[]> =>
  await fetchJSON<Tag[]>({ sessionId, url: "/api/tags" })
    .then((r) => r.data)
    .catch((err) => Promise.reject(errorParser("tags", err)));

function Tags() {
  const sessionId = useUIStore((s) => s.sessionId);
  const tagsData = useQuery({
    queryKey: LOAD_TAGS_KEY,
    queryFn: loadTags(sessionId),
  });
  const topEntries = useQuery({
    queryKey: LOAD_TOPENTRIES_KEY,
    queryFn: loadTopEntries(sessionId),
  });
  const hasTopEntries = topEntries.isSuccess
    ? topEntries.data.length > 0
    : true;
  useEffect(() => {
    useUIStore.setState(
      produce((s: IUIStore) => {
        if (tagsData.data === undefined) {
          return;
        }
        const tags = [...tagsData.data];
        tags.sort((a, b) => a.name.localeCompare(b.name));
        s.highestTags = tags;
      })
    );
  }, [tagsData.data]);
  const tags = useUIStore((s) => s.highestTags);
  const queryClient = useQueryClient();

  useEffect(() => {
    const interval = setInterval(() => {
      void queryClient.invalidateQueries({ queryKey: LOAD_TAGS_KEY });
    }, 30000);
    return () => clearInterval(interval);
  }, [queryClient]);

  useEffect(() => {
    document.title = "Freshet: Tags";
  }, []);
  return (
    <div id="tags-tab" className="col" data-testid="tags-tab">
      <h4>Highest scored tags with unread entries</h4>
      {tags.length === 0 && !tagsData.isSuccess && (
        <span className="fs-6" data-testid="loading-tags">
          Loading...
        </span>
      )}
      {tags.length === 0 && tagsData.isSuccess && hasTopEntries && (
        <span className="fs-6" data-testid="no-scored-msg">
          You haven&apos;t scored any entries yet. Please goto Best or Recent
          tabs and click &quot;More&quot; or &quot;Less&quot; on at least one
          entry.
        </span>
      )}
      {tags.length === 0 && topEntries.isSuccess && !hasTopEntries && (
        <span className="fs-6" data-testid="no-unread-msg">
          Congratulations! You&apos;ve read everything. Maybe you should
          subscribe to more <Link to={"/Feeds"}>Feeds</Link>?
        </span>
      )}
      <ul>
        {tags.map((tag) => {
          const urlName = tag.name.replaceAll(new RegExp("[^A-Za-z]", "g"), "");
          return (
            <li key={tag.id} data-testid="tag-item">
              <Link to={`/Tag/${tag.id}-${urlName}?name=${tag.name}`}>
                {tag.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Tags;
