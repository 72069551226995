import { LOAD_CONFIG_KEY, LOAD_FEEDS_KEY, loadConfig } from "./Loaders";
import { IUIStore, useUIStore } from "./Store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { useLayoutEffect, useState } from "react";

interface FeedsMessage {
  kind: "feeds";
}

interface ScoreMessage {
  kind: "score";
  data: {
    account_entry_id: string;
    entry_id: string;
    feed_id: string;
    score: number;
  };
}

type SocketMessage = FeedsMessage | ScoreMessage;

interface SessionStart {
  kind: "sessionStart";
  sessionId: string;
}

export const useWebsocket = () => {
  const sessionId = useUIStore((s) => s.sessionId);
  const queryClient = useQueryClient();
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const config = useQuery({
    queryKey: LOAD_CONFIG_KEY.concat(...sessionId),
    queryFn: loadConfig(sessionId),
  });
  const accountId = useUIStore((s) => s.loginData?.id);

  useLayoutEffect(() => {
    const info_logger = __WEBSOCKET_TESTING__ ? console.warn : console.info;
    const debug_logger = __WEBSOCKET_TESTING__ ? console.warn : console.debug;

    debug_logger(
      `Websocket state: accountId=${accountId} config=${config.isSuccess} websocket ${websocket}`
    );
    if (accountId !== undefined && config.isSuccess && websocket === null) {
      const client = new WebSocket(config.data.websocket);
      info_logger("websocket connecting...");
      client.onmessage = (message) => {
        debug_logger("Websocket message", message);
        const decoded = JSON.parse(message.data as string) as SocketMessage;
        switch (decoded.kind) {
          case "score":
            debug_logger("Updating entry because of score change", decoded);
            useUIStore.setState(
              produce((s: IUIStore) => {
                let found = false;
                for (const key of Object.keys(s.storedEntries)) {
                  const existingIndex = s.storedEntries[key].entries.findIndex(
                    (ae) => ae.entry.id === decoded.data.entry_id
                  );
                  if (existingIndex !== -1) {
                    s.storedEntries[key].entries[existingIndex] = {
                      ...s.storedEntries[key].entries[existingIndex],
                      score: decoded.data.score,
                    };
                    found = true;
                  }
                }
                if (!found) {
                  console.warn(
                    `Can't find ${decoded.data.entry_id} for scoring`
                  );
                }
              })
            );
            break;
          case "feeds":
            debug_logger("reloading feeds");
            void queryClient.invalidateQueries({ queryKey: LOAD_FEEDS_KEY });
            break;
          default:
            console.warn("unknown message", decoded);
        }
      };
      client.onopen = () => {
        info_logger("websocket connected");
        setWebsocket(client);
        client.send(
          JSON.stringify({
            kind: "sessionStart",
            sessionId: sessionId,
          } as SessionStart)
        );
      };
      client.onerror = (error) => {
        info_logger("Websocket error", JSON.stringify(error));
        setWebsocket(null);
      };
      client.onclose = () => {
        info_logger("Websocket closed");
        setWebsocket(null);
      };
    }
    return () => {
      if (websocket !== null) {
        websocket.onopen = null;
        websocket.onclose = null;
        websocket.close();
        setWebsocket(null);
      }
    };
  }, [
    sessionId,
    accountId,
    config.data?.websocket,
    config.isSuccess,
    queryClient,
    websocket,
  ]);

  return websocket;
};
