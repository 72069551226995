import { AccountEntry, LoadingAccountEntry, Tag } from "./Data";
import { parseAccountEntries } from "./Parsers";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface Login {
  email: string;
  id: number;
}

export interface StoredEntry {
  when: DateTime;
  entries: AccountEntry[];
}

interface LoadingStoredEntry {
  when: string;
  entries: LoadingAccountEntry[];
}

export interface IUIStore {
  loginData: Login | null;
  storedEntries: { [key: string]: StoredEntry };
  clickedEntries: string[];
  toRemoveClickedEntries: string[];
  scoredEntries: string[];
  toRemoveScoredEntries: string[];
  tagNames: { [key: string]: string };
  highestTags: Tag[];
  toasts: string[];
  sessionId: string;
}

export const MAX_STORED_COUNT = 50;
export const MAX_SCORED_ENTRIES = 50;

type LoadingIUIStore = Omit<IUIStore, "storedEntries" | "sessionId"> & {
  storedEntries: { [key: string]: LoadingStoredEntry | LoadingAccountEntry[] };
  sessionId?: string;
};

export const defaultData = () =>
  ({
    loginData: null,
    storedEntries: {},
    clickedEntries: [],
    toRemoveClickedEntries: [],
    scoredEntries: [],
    toRemoveScoredEntries: [],
    tagNames: {},
    highestTags: [],
    toasts: [],
    sessionId: v4(),
  } as IUIStore);

export const makeUIStore = () =>
  create<IUIStore>()(
    devtools(
      persist(defaultData, {
        name: "freshet",
        storage: {
          getItem: (name) => {
            try {
              const rawValue = localStorage.getItem(name);
              if (typeof rawValue !== "string") {
                return null;
              }
              const state = (JSON.parse(rawValue) as { state: LoadingIUIStore })
                .state;
              const newStoredEntries: { [key: string]: StoredEntry } = {};
              for (const [key, value] of Object.entries(state.storedEntries)) {
                if (Array.isArray(value)) {
                  newStoredEntries[key] = {
                    when: DateTime.now(),
                    entries: parseAccountEntries(value),
                  };
                } else {
                  newStoredEntries[key] = {
                    when: DateTime.fromISO(value.when),
                    entries: parseAccountEntries(value.entries),
                  };
                }
              }
              const newState: IUIStore = {
                ...state,
                storedEntries: newStoredEntries,
                sessionId: state.sessionId ?? v4(),
              };
              return {
                state: newState,
              };
            } catch (error) {
              console.warn(`Got error ${error}: ${(error as Error).stack}`);
              throw error;
            }
          },
          setItem: (name, newValue) => {
            const rawValue = JSON.stringify({
              ...newValue,
            });
            localStorage.setItem(name, rawValue);
          },
          removeItem: localStorage.removeItem,
        },
      })
    )
  );

export const useUIStore = makeUIStore();
