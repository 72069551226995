import { DisplayEntry } from "./Entry";
import { AccountEntry, LoadingAccountEntry } from "../components/Data";
import {
  fetchJSON,
  LOAD_HISTORY_KEY,
  LOAD_RECENTENTRIES_KEY,
  LOAD_SEARCH_KEY,
  LOAD_TOPENTRIES_KEY,
  LoaderFunction,
  loadHistory,
  loadRecentEntries,
  loadTopEntries,
  useFeeds,
} from "../components/Loaders";
import { parseAccountEntries, SelectFunction } from "../components/Parsers";
import { IUIStore, MAX_STORED_COUNT, useUIStore } from "../components/Store";
import { Query, QueryClient, useQuery } from "@tanstack/react-query";
import { produce } from "immer";
import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function useCachedEntries<LoadingType>(
  loadkey: string[],
  loader: LoaderFunction<LoadingType>,
  entriesKey: string,
  selectFunction: SelectFunction<LoadingType>,
  historyEntries: boolean,
  refetchInterval?: (
    query: Query<LoadingType, unknown, LoadingType, string[]>
  ) => number
) {
  const sessionId = useUIStore((s) => s.sessionId);
  const { isFetching, isSuccess, data } = useQuery({
    queryKey: loadkey,
    queryFn: loader,
    select: selectFunction,
    throwOnError: (err) => {
      console.warn(`Failure getting ${loadkey}: ${err}`);
      return false;
    },
    refetchInterval,
  });
  useEffect(() => {
    if (isFetching) {
      useUIStore.setState(
        produce((s: IUIStore) => {
          for (const item of s.clickedEntries) {
            if (!s.toRemoveClickedEntries.includes(item)) {
              s.toRemoveClickedEntries.push(item);
            }
          }
          for (const item of s.scoredEntries) {
            if (!s.toRemoveScoredEntries.includes(item)) {
              s.toRemoveScoredEntries.push(item);
            }
          }
        })
      );
    }
  }, [isFetching]);
  useEffect(() => {
    if (isSuccess) {
      useUIStore.setState(
        produce((s: IUIStore) => {
          if (historyEntries) {
            s.storedEntries[entriesKey] = {
              when: DateTime.now(),
              entries: data,
            };
            return;
          }

          if (!(entriesKey in s.storedEntries)) {
            s.storedEntries[entriesKey] = { when: DateTime.now(), entries: [] };
            while (Object.keys(s.storedEntries).length > MAX_STORED_COUNT) {
              const keysOldest = _.sortBy(
                Object.keys(s.storedEntries),
                (key) => s.storedEntries[key].when
              );
              console.debug("removed", keysOldest[0]);
              _.unset(s.storedEntries, keysOldest[0]);
            }
          }
          s.storedEntries[entriesKey].when = DateTime.now();
          const entriesArray = s.storedEntries[entriesKey].entries;
          let index = 0;

          _.remove(s.scoredEntries, (c) => s.toRemoveScoredEntries.includes(c));
          s.toRemoveScoredEntries = [];

          _.remove(s.clickedEntries, (c) =>
            s.toRemoveClickedEntries.includes(c)
          );
          s.toRemoveClickedEntries = [];

          while (index < entriesArray.length) {
            if (s.clickedEntries.includes(entriesArray[index].id)) {
              index++;
            } else {
              entriesArray.splice(index, 1);
            }
          }
          for (const newAccountEntry of data) {
            const existingIndex = entriesArray.findIndex(
              (ae) => ae.id === newAccountEntry.id
            );
            if (existingIndex === -1) {
              entriesArray.push(newAccountEntry);
            } else {
              entriesArray[existingIndex] = newAccountEntry;
            }
          }
        })
      );
    }
  }, [isSuccess, data, entriesKey, sessionId, historyEntries]);
  return { isFetching };
}

export async function onSuccessfulRescore(
  loadkey: string[],
  queryClient: QueryClient,
  entryId: string
) {
  useUIStore.setState(
    produce((s: IUIStore) => {
      let found = false;
      for (const [key, storedEntry] of Object.entries(s.storedEntries)) {
        const existingIndex = storedEntry.entries.findIndex(
          (ae) => ae.entry.id === entryId
        );
        if (existingIndex !== -1) {
          if (storedEntry.entries.length === 1) {
            _.unset(s.storedEntries, key);
          } else {
            storedEntry.entries.splice(existingIndex, 1);
          }
          found = true;
        }
      }
      if (found) {
        _.pull(s.clickedEntries, entryId);
      } else {
        console.warn(`Can't find existing entry ${entryId}`);
      }
      return s;
    })
  );
  await queryClient.invalidateQueries({ queryKey: loadkey });
}

export function WorkingEntries<LoadingType>({
  loadkey,
  loader,
  entriesKey,
  noUnreadElement,
  selectFunction,
}: {
  loadkey: string[];
  loader: LoaderFunction<LoadingType>;
  entriesKey: string;
  noUnreadElement: JSX.Element;
  selectFunction: SelectFunction<LoadingType>;
}) {
  const sessionId = useUIStore((s) => s.sessionId);
  const feeds = useFeeds(sessionId);
  const feedList = feeds.data || [];
  const feedIds = feedList.map((f) => f.id);
  let allEntries = [];
  const entryLimit = 5;

  // Bit of a hack, but lets use show entries for historical/search bits
  const searchEntries = loadkey[0] === LOAD_SEARCH_KEY[0];
  const historyEntries = loadkey === LOAD_HISTORY_KEY || searchEntries;

  const { isFetching } = useCachedEntries(
    loadkey,
    loader,
    entriesKey,
    selectFunction,
    historyEntries,
    () => (allEntries.length >= entryLimit ? 30000 : 5000)
  );
  allEntries = useUIStore(
    (s) =>
      _.get(s.storedEntries, entriesKey, { entries: [] as AccountEntry[] })
        .entries
  );
  const scoredEntries = useUIStore((s) => s.scoredEntries);

  const entries = allEntries.filter(
    (accountEntry) =>
      _.intersection(feedIds, accountEntry.feeds).length > 0 &&
      (historyEntries ||
        (accountEntry.more_like_this === null &&
          !_.includes(scoredEntries, accountEntry.entry.id)))
  );
  const workingFeeds = feedList.filter((feed) => feed.last_success !== null);
  const clickedEntries = useUIStore((s) => s.clickedEntries);

  const filterWithClicked: AccountEntry[] = [];
  if (historyEntries) {
    filterWithClicked.push(...entries);
  } else {
    for (const ae of entries) {
      if (ae.has_been_clicked || _.includes(clickedEntries, ae.id)) {
        filterWithClicked.push(ae);
      }
      if (filterWithClicked.length === entryLimit) {
        break;
      }
    }

    if (filterWithClicked.length < entryLimit) {
      const notClicked = entries.filter(
        (ae) => !ae.has_been_clicked && !_.includes(clickedEntries, ae.id)
      );
      filterWithClicked.push(
        ...notClicked.slice(0, entryLimit - filterWithClicked.length)
      );
    }
  }

  return (
    <>
      <br className="d-sm-block d-md-none" />
      {!feeds.isLoading && workingFeeds.length === 0 && (
        <span data-testid="no-working-feeds">No working feeds</span>
      )}
      {filterWithClicked.length === 0 &&
        (isFetching ? (
          <span className="fs-6" data-testid="loading-entries">
            Loading...
          </span>
        ) : (
          <span className="fs-6" data-testid="no-unread-msg">
            {noUnreadElement}
          </span>
        ))}
      <span id="loaded-feeds" data-testid="loaded-feeds">
        {workingFeeds.length > 0 &&
          filterWithClicked.slice(0, 5).map((accountEntry) => (
            <DisplayEntry
              key={accountEntry.id}
              accountEntry={accountEntry}
              hasClicked={
                historyEntries && !searchEntries
                  ? accountEntry.more_like_this === null
                  : accountEntry.has_been_clicked ||
                    _.includes(clickedEntries, accountEntry.id)
              }
              setHasClicked={() => {
                useUIStore.setState(
                  produce((s: IUIStore) => {
                    s.clickedEntries.push(accountEntry.id);
                    void fetchJSON({
                      url: `/api/entry/${accountEntry.entry.id}/mark_as_clicked`,
                      method: "PUT",
                    });
                  })
                );
              }}
              onSuccessfulRescore={(queryClient, entryId) =>
                onSuccessfulRescore(loadkey, queryClient, entryId)
              }
            />
          ))}
      </span>
    </>
  );
}

export function Entries({
  name,
  loadkey,
  loader,
  entriesKey,
  noUnreadElement,
}: {
  name: string;
  loadkey: string[];
  loader: LoaderFunction<LoadingAccountEntry[]>;
  entriesKey: string;
  noUnreadElement: JSX.Element;
}) {
  const sessionId = useUIStore((s) => s.sessionId);
  let workingFeeds = [];
  const feeds = useFeeds(sessionId, () =>
    workingFeeds.length > 0 ? 30000 : 5000
  );
  workingFeeds = (feeds.data ?? []).filter(
    (feed) => feed.last_success !== null
  );

  useEffect(() => {
    document.title = `Freshet: ${name}`;
  }, [name]);

  return (
    <div id="entries-tab" className="col" data-testid="entries-tab">
      {(feeds.data ?? []).length > 0 ? (
        <div className="row">
          <div className="col">
            <WorkingEntries
              loadkey={loadkey}
              loader={loader}
              entriesKey={entriesKey}
              selectFunction={parseAccountEntries}
              noUnreadElement={noUnreadElement}
            />
          </div>
        </div>
      ) : feeds.isSuccess ? (
        <span data-testid="no-feeds">No feeds</span>
      ) : (
        <span data-testid="loading-feeds">Loading...</span>
      )}
    </div>
  );
}

const feedNoUnreadElement = (
  <>
    Congratulations! You&apos;ve read everything. Maybe you should subscribe to
    more <Link to={"/Feeds"}>Feeds</Link>?
  </>
);

export const TopEntries = ({ sessionId }: { sessionId: string }) => (
  <Entries
    name="Best"
    loadkey={LOAD_TOPENTRIES_KEY}
    loader={loadTopEntries(sessionId)}
    entriesKey={"top"}
    noUnreadElement={feedNoUnreadElement}
  />
);

export const RecentEntries = ({ sessionId }: { sessionId: string }) => (
  <Entries
    name="Recent"
    loadkey={LOAD_RECENTENTRIES_KEY}
    loader={loadRecentEntries(sessionId)}
    entriesKey={"recent"}
    noUnreadElement={feedNoUnreadElement}
  />
);

export const History = ({ sessionId }: { sessionId: string }) => (
  <Entries
    name="History"
    loadkey={LOAD_HISTORY_KEY}
    loader={loadHistory(sessionId)}
    entriesKey={"history"}
    noUnreadElement={
      <>
        You don&apos;t have any recent history. Try clicking on some links or
        Most/Meh/Less buttons.
      </>
    }
  />
);
