import {
  Account,
  AccountState,
  Config,
  Feed,
  LoadingAccountEntry,
  LoadingFeed,
} from "./Data";
import { useUIStore } from "./Store";
import { Query, useQuery, UseQueryResult } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import _ from "lodash";
import { DateTime } from "luxon";

axiosRetry(axios, { retries: 3, shouldResetTimeout: true });

export function fetchJSON<T>({
  sessionId,
  url,
  method = "GET",
  content = null,
  signal,
}: {
  sessionId?: string;
  url: string;
  method?: string;
  content?: unknown;
  signal?: AbortSignal;
}): Promise<AxiosResponse<T>> {
  let res;
  if (method === "GET" && sessionId === undefined) {
    console.error(`sessionid not set on GET for ${url}`);
    throw Error("sessionid not set on GET");
  }
  const localUrl =
    sessionId === undefined
      ? url
      : url.indexOf("?") === -1
      ? `${url}?sessionId=${sessionId}`
      : `${url}&sessionId=${sessionId}`;
  const args = { url: localUrl, method: method, signal, timeout: 10000 };
  if (content === null) {
    res = axios<T>(args);
  } else {
    res = axios<T, AxiosResponse<T, AxiosError>>({
      ...args,
      headers: {
        "Content-Type": "application/json",
      },
      data: content,
    });
  }
  return res.catch((err) => {
    const code = errorCode(err);
    if (code === 401) {
      useUIStore.setState((s) => {
        console.debug("Got a 401, so logging you out");
        return { ...s, loginData: null };
      });
    }
    throw err;
  });
}

export function isNullOrUndefined(val: unknown): val is null | undefined {
  return _.isNull(val) || _.isUndefined(val);
}

export const errorParser = (kind: string, error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return `Couldn't get ${kind} - ${error.status}: ${JSON.stringify(
        error.response.data
      )}`;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return `Server didn't respond to request for ${kind}`;
    } else {
      // Something happened in setting up the request that triggered an Error
      return `Some other error: ${error.message}`;
    }
  } else {
    return `Couldn't get ${kind}: ${error}`;
  }
};

export const errorData = (error: unknown): unknown => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return error.response.data;
    }
  }
  return null;
};

export const errorCode = (error: unknown): number | null => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return error.response.status;
    }
  }
  return null;
};

export const LOAD_FEEDS_KEY = ["feeds"];
const _loadFeeds = async (
  sessionId: string,
  signal?: AbortSignal
): Promise<LoadingFeed[]> =>
  await fetchJSON<LoadingFeed[]>({ sessionId, url: "/api/feeds", signal })
    .then((result) => result.data)
    .catch((result) => Promise.reject(errorParser("feeds", result)));

const selectFeed = (r: LoadingFeed[]) =>
  r.map((feed) => ({
    ...feed,
    last_update: isNullOrUndefined(feed.last_update)
      ? null
      : DateTime.fromISO(feed.last_update),
    last_success: isNullOrUndefined(feed.last_success)
      ? null
      : DateTime.fromISO(feed.last_success),
  }));

export const useFeeds = (
  sessionId: string,
  refetchInterval?: (
    query: Query<LoadingFeed[], unknown, LoadingFeed[], string[]>
  ) => number
): UseQueryResult<Feed[], unknown> =>
  useQuery({
    queryKey: LOAD_FEEDS_KEY,
    queryFn: ({ signal }) => _loadFeeds(sessionId, signal),
    select: selectFeed,
    refetchInterval,
  });

export const LOAD_CONFIG_KEY = ["config"];
export const loadConfig = (sessionId: string) => async (): Promise<Config> =>
  await fetchJSON<Config>({ sessionId, url: "/api/config" })
    .then((result) => result.data)
    .catch((result) => Promise.reject(errorParser("config", result)));

export type LoaderFunction<Type> = () => Promise<Type>;

export const LOAD_TOPENTRIES_KEY = ["topentries"];
export const loadTopEntries =
  (sessionId: string) => async (): Promise<LoadingAccountEntry[]> =>
    await fetchJSON<LoadingAccountEntry[]>({
      sessionId,
      url: "/api/feed/top_entries",
    })
      .then((result) => result.data)
      .catch((error) => Promise.reject(errorParser("top entries", error)));

export const LOAD_RECENTENTRIES_KEY = ["recententries"];
export const loadRecentEntries =
  (sessionId: string) => async (): Promise<LoadingAccountEntry[]> =>
    await fetchJSON<LoadingAccountEntry[]>({
      sessionId,
      url: "/api/feed/recent_entries",
    })
      .then((result) => result.data)
      .catch((error) => Promise.reject(errorParser("recent entries", error)));

export const LOAD_HISTORY_KEY = ["history"];
export const loadHistory =
  (sessionId: string) => async (): Promise<LoadingAccountEntry[]> =>
    await fetchJSON<LoadingAccountEntry[]>({
      sessionId,
      url: "/api/feed/most_recently_changed",
    })
      .then((result) => result.data)
      .catch((error) => Promise.reject(errorParser("history", error)));

export const LOAD_SEARCH_KEY = ["search"];
export const loadSearch =
  (sessionId: string) =>
  async (terms: string): Promise<LoadingAccountEntry[]> =>
    await fetchJSON<LoadingAccountEntry[]>({
      sessionId,
      url: `/api/feed/search?query=${terms}`,
    })
      .then((result) => result.data)
      .catch((error) => Promise.reject(errorParser("search", error)));

export const hasWorkingFeedsTest = (
  feeds: UseQueryResult<Feed[], unknown>
): boolean =>
  feeds.isSuccess
    ? feeds.data.filter((f) => f.last_success !== null).length > 0
    : true; // Default true as most users will have feeds

export const hasFeedsTest = (feeds: UseQueryResult<Feed[], unknown>): boolean =>
  feeds.isSuccess ? feeds.data.length > 0 : true; // Default true as most users will have feeds

type LoadingAccount = Omit<Account, "state"> & {
  state: string;
};

export const LOAD_ACCOUNT = ["account"];
const loadAccount = (sessionId: string) => {
  const internal = async (): Promise<LoadingAccount> =>
    await fetchJSON<LoadingAccount>({ sessionId, url: "/api/account" })
      .then((result) => result.data)
      .catch((error) => Promise.reject(errorParser("account", error)));
  return internal;
};

const selectAccount = (a: LoadingAccount) => ({
  ...a,
  state: AccountState[a.state as keyof typeof AccountState],
});

export const useAccount = (
  sessionId: string
): UseQueryResult<Account, unknown> =>
  useQuery({
    queryKey: LOAD_ACCOUNT,
    queryFn: loadAccount(sessionId),
    select: selectAccount,
  });

interface Version {
  version: string;
}

export const LOAD_VERSION_KEY = ["version"];
export const loadVersion = (sessionId: string) => async (): Promise<Version> =>
  await fetchJSON<Version>({ sessionId, url: "/api/version" })
    .then((result) => result.data)
    .catch((error) => Promise.reject(errorParser("version", error)));
