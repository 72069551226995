import "../sass/site.scss";
import App from "./components/App";
import * as Sentry from "@sentry/browser";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { enableMapSet } from "immer";
import { createRoot } from "react-dom/client";

enableMapSet();

if (!["nginx", "localhost"].includes(window.location.hostname)) {
  console.debug(`Loading sentry for ${window.location.hostname}`);
  Sentry.init({
    dsn: "https://e59adec6493889e7b9cc2e4610139403@o4505739072241664.ingest.sentry.io/4505739074732032",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:app.freshet.net/api/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.debug(`No sentry because we're on ${window.location.hostname}`);
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

root.render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{
      persister,
    }}
  >
    <App />
  </PersistQueryClientProvider>
);
